import { observer } from "mobx-react";
import {
  DataGrid
} from '@mui/x-data-grid';
import React from "react";
import store from "./store";
import styled from "styled-components";
import { GridColDef } from "@mui/x-data-grid/models";
import dayjs from "dayjs";
import ReloadIcon from "./icons/ReloadIcon";

type SurveyTableProps = {
};

const SurveyTable: React.FC<SurveyTableProps> = observer((props) => {
  const tableData: GridColDef[] = [
    {
      field: "survey_status",
      renderHeader: () => {
        return <span id="Survey_View_Table_Header_ProjectStatus">Status</span>
      },
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return <span id={"Survey_View_Table_ProjectStatus_" + params.row.id}>{params.row.project_status}</span>
      },
    },
    {
      field: "survey_type",
      renderHeader: () => {
        return <span id="Survey_View_Table_Header_SurveyType">Survey type</span>
      },
      sortable: false,
      disableColumnMenu: true,
      flex: 1.4,
      renderCell: (params) => {
        return <span id={"Survey_View_Table_SurveyType_" + params.row.id}>{params.row.survey_type} {params.row.frequency_repeat && `: ${params.row.frequency_repeat}`}</span>
      },
    },
    {
      field: "tags",
      renderHeader: () => {
        return <span id="Survey_View_Table_Header_Tags">Tags</span>
      },
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return <span id={"Survey_View_Tables_Tags_" + params.row.id}>{params.row.tags}</span>
      },
    },
    {
      field: "rating_scale_min",
      renderHeader: () => {
        return <span id="Survey_View_Table_Header_RatingScale">Rating scale</span>
      },
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return <span id={"Survey_View_Table_RatingScale_" + params.row.id}>{params.row.rating_scale_min} - {params.row.rating_scale_max}</span>
      }
    },
    {
      field: "participation_count",
      renderHeader: () => {
        return <span id="Survey_View_Table_Header_ParticipationRate">Participation rate</span>
      },
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return <span id={"Survey_View_Table_ParticipationRate_" + params.row.id}>{params.row.participation_count_done} / {params.row.participation_count} ({params.row.participation_count_percent}%)</span>
      }
    },
    {
      field: "trigger",
      renderHeader: () => {
        return <span id="Survey_View_Table_Header_RecipientTrigger">Recipient trigger</span>
      },
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => {
        return <span id={"Survey_View_Table_RecipientTrigger_" + params.row.id}>{params.row.trigger}</span>
      },
    },
    {
      field: "stard_date",
      renderHeader: () => {
        return <span id="Survey_View_Table_Header_StartDate">Start date</span>
      },
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        let formattedDate = "";
        const minutes_offset = dayjs().utcOffset();
        if (params.row.stard_date) {
          const da = dayjs(params.row.stard_date);
          const survey_date_update = dayjs(da)
            .add(minutes_offset, "minute")
            .toDate()
          formattedDate = new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }).format(survey_date_update);
        }
        return <span id={"Survey_View_Table_StardDate_" + params.row.id}>{formattedDate}</span>
      },
    },
    {
      field: "end_date",
      headerName: "End date",
      renderHeader: () => {
        return <span id="Survey_View_Table_Header_EndDate">End date</span>
      },
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        let formattedDate = "";
        const minutes_offset = dayjs().utcOffset();
        if (params.row.end_date) {
          const da = dayjs(params.row.end_date);
          const survey_date_update = dayjs(da)
            .add(minutes_offset, "minute")
            .toDate()
          formattedDate = new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }).format(survey_date_update);
        }
        return <span id={"Survey_View_Table_EndDate_" + params.row.id}>{formattedDate}</span>
      },
    },
    {
      field: "created_at",
      renderHeader: () => {
        return <span id="Survey_View_Table_Header_CreatedAt">Created at</span>
      },
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        let formattedDate = "";
        const minutes_offset = dayjs().utcOffset();
        if (params.row.created_at) {
          const da = dayjs(params.row.created_at);
          const survey_date_update = dayjs(da)
            .add(minutes_offset, "minute")
            .toDate()
          formattedDate = new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }).format(survey_date_update);
        }
        return <span id={"Survey_View_Table_CreatedAt_" + params.row.id}>{formattedDate}</span>
      },
    },
  ];

  const showSurveyUpdateAt = () => {
    const survey_date_update =new Date(dayjs(store.survey?.updated_at).toDate() + " UTC")

    return new Intl.DateTimeFormat("en-SG", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: "short",
    }).format(survey_date_update).replace("am", "AM").replace("pm", "PM")
  }

  return (
    <Container>
      <EmployeeListBlock>
        <EmployeeListText id="Survey_View_Table_EmployeelistlastUpdate">
          Employee list last update:
        </EmployeeListText>
        <EmployeeListDate id="Survey_View_Table_EmployeelistlastUpdate_Date">
          {showSurveyUpdateAt()}
        </EmployeeListDate>
        <UpdateAttributes id="Survey_View_Table_UpdateRespondentAttributes" onClick={() => store.setData("openUpdateAttributes", true)}>
          <ReloadIcon />
          Update respondent attributes
        </UpdateAttributes>

      </EmployeeListBlock>

      <StyledDataGrid
        rows={store.survey !== null ? [store.survey] : []}
        columns={tableData}
        hideFooter
      />
    </Container>
  );
});


export default SurveyTable;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

const EmployeeListBlock = styled.div`
  display: flex;
  border: 1px solid var(--colorNeutralForeground9, #E0E0E0);
  padding: 5px 10px;
  background-color: #FFFFFF;
`

const EmployeeListText = styled.div`
  color: var(--colorNeutralForeground2, #707882);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const EmployeeListDate = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-left: 7px;
`

const UpdateAttributes = styled.div`
  color: var(--colorBrandForeground1, #1477F8);
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
  margin-left: 10px;
`


const StyledDataGrid = styled(DataGrid)`
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 0px !important;

  .MuiDataGrid-columnHeaderTitleContainer {
    background-color: var(--colorPaletteBlueBackground2);
  }

  .MuiDataGrid-columnHeaders {
    background-color: var(--colorPaletteBlueBackground2);
    border-radius: 0px !important;
    text-transform: uppercase !important;
  }

  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-cell {
    cursor: pointer !important;
  }

  .Mui-selected {
    background-color: transparent !important;
  }
  .MuiCheckbox-root {
    color: var(--colorBrandForeground1) !important;
  }


  .MuiDataGrid-root {
    border: none !important;
  }
  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .Mui-odd {
    background-color: var(--colorNeutralForeground8);
  }
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    border-right: 1px solid var(--graySelectBoxBorder);
  }
`;
