import { TSurvey } from "types/survey";
import { makeAutoObservable } from "mobx";
import MainStore from "MainStore";
import getSurveyInformation from "api/useGetSurveyInformation";
import dayjs, { Dayjs } from "dayjs";
import { getTimeZone } from "utils/timeFormatter";
import { saveReopenSurvey } from "api/useSaveReopenSurvey";
import { saveSurveyThreshold } from "api/useSaveSurveyThreshold";
import { updateRespondentAttributes } from "api/useUpdateRespondentAttributes";
import { updateSurveyUpdateAt } from "api/useUpdateSurveyUpdateAt";
import getRawSurveyResponses from "api/useGetRawSurveyResponses";

class Store {
  surveyId: number = 0;
  survey: TSurvey = null;

  minResponses: number = 0;
  startDate: Dayjs = null;
  endDate: Dayjs = null;
  milestoneStart: Dayjs = null;
  timezoneMessage = getTimeZone();

  thresholdChanged: boolean = false;
  datesChanged: boolean = false;
  isReopened: boolean = false;

  openUpdateAttributes: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  clearStore = () => {};

  setData = (field: string, value: any) => {
    this[field] = value;
  };

  changeMinResponse = (value: number) => {
    this.minResponses = value;
    this.thresholdChanged = true;
  };

  changeStartDate = (value: Dayjs) => {
    this.startDate = value;
    this.datesChanged = true;
  };

  changeMilestoneStart = (value: Dayjs) => {
    this.milestoneStart = value;
    this.datesChanged = true;
  };

  changeEndDate = (value: Dayjs) => {
    this.endDate = value;
    this.datesChanged = true;
  };

  doLoad = (surveyId: number) => {
    this.setData("surveyId", surveyId);
    this.getSurveyData();
  };

  setSurveyData = (survey: TSurvey) => {
    this.survey = survey;
    const minutes_offset = dayjs().utcOffset();
    this.minResponses = survey.min_responses;
    this.startDate =
      survey.stard_date &&
      dayjs(survey.stard_date).add(minutes_offset, "minute");
    this.endDate =
      survey.end_date && dayjs(survey.end_date).add(minutes_offset, "minute");
    this.milestoneStart =
      survey.timestart_milestone &&
      dayjs(survey.timestart_milestone).add(minutes_offset, "minute");
  };

  changeSurveyData = (field: string, value: any) => {
    if (!this.survey) return;
    this[field] = value;
  };

  onSavedReopen = () => {
    this.isReopened = false;
    this.datesChanged = false;
    MainStore.setSnackbar(
      `${this.survey?.name} has been re-opened successfully.`
    );
    this.getSurveyData();
  };

  onReopenCancel = () => {
    this.isReopened = false;
    this.startDate = dayjs(this.survey?.stard_date);
    this.milestoneStart = dayjs(this.survey?.timestart_milestone);
    this.endDate = dayjs(this.survey?.end_date);
  };


  saveReopenSurvey = async () => {
    const utcMinutes = dayjs().utcOffset();
    const endDate = this.endDate.add(-utcMinutes, "minute").format();
    try {
      MainStore.changeLoader(true);
      const response = await saveReopenSurvey(this.surveyId, endDate);
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.onSavedReopen();
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  onSavedThreshold = () => {
    this.thresholdChanged = false;
    MainStore.setSnackbar(
      `Confidentiality threshold of ${this.survey?.project_name} has been changed successfully.`
    );
    this.getSurveyData();
  };

  onThresholdCancel = () => {
    this.thresholdChanged = false;
    this.minResponses = this.survey?.min_responses;
  };

  saveThreshold = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await saveSurveyThreshold(
        this.surveyId,
        this.minResponses
      );
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.onSavedThreshold();
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getSurveyData = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getSurveyInformation(this.surveyId);
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setSurveyData(response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
  
  updateRespondentAttributes = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await updateRespondentAttributes(this.surveyId);
      if (
        (response.status === 200 || response.status === 201) &&
        response?.data !== null
      ) {
        this.setData("openUpdateAttributes", false);
        MainStore.setSnackbar("Employee attributes and segments successfully updated for respondents of this survey")

        await updateSurveyUpdateAt(this.surveyId)
        this.getSurveyData()
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
  downloadRawSurveyResponses = async () => {
    try {
      MainStore.changeLoader(true);
      const data = await getRawSurveyResponses(this.surveyId);
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      let today = new Date();
      let day = ("0" + today.getDate()).slice(-2);
      let month = ("0" + (today.getMonth() + 1)).slice(-2);
      let year = today.getFullYear();

      a.download = `${this.survey.entity_name}_${this.survey.project_name}_${this.survey.name}_raw_responses_${day}${month}${year}.xlsx`;;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
